import React from 'react'
import { Link } from 'react-router-dom'
import './CSS/AboutUs.css'
import About from '../components/about/About'
import MessageBanner from '../components/messageBanner/MessageBanner'
import yash from '../components/assets/yash.jpg'
import prasoon from '../components/assets/prasoon.jpg'
import copper_benefits from '../components/assets/copper_benifits.jpg'

const AboutUs = () => {
    return (
        <div>
            <div className="about-page header">
                <h2>About Us</h2>
            </div>
            <About />
            <div className="container">
                <div className='benefit-copper'>
                    <div className="image">
                        <img src={copper_benefits} alt="" /> 
                    </div>
                    <div className="content">
                        <h3>The Benefits of Copper Bottles</h3>
                        <p>Incorporating copper bottles into your lifestyle isn't merely a trend; it's a timeless tradition rooted in holistic wellness. From bolstering immunity to promoting youthful vitality, the benefits of copper extend far beyond mere hydration. Embrace the wisdom of ancient practices and embark on a journey towards optimal health with copper bottles as your trusted companions. Experience the transformative power of copper and elevate your well-being one sip at a time.</p>

                        <p>As we navigate the complexities of modern life, let us not forget the timeless remedies gifted to us by our ancestors. Embrace the embrace of copper, honor the sanctity of your well-being, and embark on a journey towards a healthier, more vibrant existence. With each sip from your copper vessel, may you be reminded of the profound connection between nature and nourishment, and may your wellness journey be as enduring as the metal itself.</p>
                        <p>
                        Beyond mere hydration, copper bottles offer a gateway to a life infused with vitality and resilience. As we stand at the crossroads of convenience and conscientious living, let us choose the path that honors our bodies and the planet. Join the movement towards holistic wellness, and let the luster of copper illuminate your journey to optimal health. With every sip, may you not only nourish your body but also nurture a deeper connection to the timeless rhythms of nature. Choose copper, choose wellness, choose a future where health and harmony reign supreme.
                        </p>
                        <Link to='/shop'><button>Shop Now</button></Link>
                    </div>

                </div>
            </div>
            <MessageBanner text={'Together, we can make a difference in the world, one sip at a time.'} />
                    
        </div>
    )
}

export default AboutUs
